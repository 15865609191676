/* General container styling */
.calendar-container {
  text-align: center;
  margin: 40px auto;
  max-width: 800px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Calendar navigation */
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.react-calendar__tile--active {
  background: #ffee01;
  color: white;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 1.5em;
  background-color: #7ebcff;
  border: solid black 1px;
  font-size: 14px;
}
.calendar-navigation button {
  background: #007bff;
  color: white;
  border: solid 1px #000000;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
}

.calendar-navigation button:hover {
  background: #0056b3;
}
.react-calendar button {
  border: solid 1px #000000;
  border-radius: 0%;
}
.month-year {
  font-size: 18px;
  font-weight: bold;
}

/* Custom calendar styles */
.custom-calendar {
  width: 100%;
}

.react-calendar__tile {
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 1px solid #ccc; /* Added border */
}

/* Normal days - black */
.normal-tile {
  color: black;
  font-size: 14px;
}

/* Sundays - red */
.sunday-tile {
  color: red;
  font-weight: bold;
  font-size: 14px;
}

/* Holidays - orange background */
.holiday-tile {
  background-color: rgb(50, 136, 248) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold;
  border-radius: 8px;
}

.react-calendar__tile:hover {
  background: #5f7686;
}
.react-calendar__navigation {
  display: none;
}

@media only screen and (max-width: 600px) {
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    background-color: #7ebcff;
    border: solid black 1px;
    font-size: 14px;
  }
}
