.travel-form-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

label {
  margin-top: 10px;
  font-weight: bold;
  color: #555;
}

input,
select,
textarea {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}

button {
  margin-top: 20px;
  padding: 10px;
  background: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: #0056b3;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 10px;
}

.radio-group {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}
