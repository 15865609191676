.card-container {
  max-width: 800px;
  margin: 50px auto;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.text-dom{
  display: flex;
  padding: 0 20px;
}
.card-img {
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 20px;
}
.card-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.7rem;
  margin-bottom: 12px;
  margin-left: 5px;
}

.card-text {
  margin-bottom: 8px;
  font-size: 1.2rem;
}

.btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}

.btn:hover {
  background-color: #0056b3;
}
@media only screen and (max-width: 530px) {
  .card-header{
    flex-direction: column;
    justify-items: center;
  }
}