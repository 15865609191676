.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  margin-bottom: 20px;
  color: #333;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.leave-table {
  border-collapse: collapse;
  width: 80%;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.leave-table th {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 18px;
}
.leave-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

.leave-table th {
  background-color: #1877f2;
  color: white;
}

.leave-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.leave-table tr:hover {
  background-color: #ddd;
}
.leave-counts {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.leave-box {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leave-box h3 {
  margin-bottom: 0.5rem;
  margin-top: 0px;
  font-size: 1.2rem;
}

.leave-box p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
