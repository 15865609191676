.intervention-data-component {
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}
.action-icons-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #ff0000;
}
h2,
h3 {
  text-align: center;
  color: #333;
}
.date-filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.error-message {
  color: red;
  text-align: center;
}

.filter-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-controls label {
  font-weight: bold;
  margin-right: 8px;
  font-size: 16px;
}

.filter-controls .react-select__control {
  min-width: 220px;
}

.intervention-counts {
  margin-bottom: 20px;
}

.intervention-counts p {
  font-size: 16px;
  font-weight: bold;
}

.intervention-table {
  overflow-x: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  
}

.bmc-admin-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
  table-layout: fixed;
}

thead th {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  position: sticky;
}
th {
  position: relative;
  background-color: #f3f3f3;
  padding: 8px;
  cursor: col-resize; /* Shows resize cursor */
  user-select: none;
}

.resizer {
  width: 5px;
  cursor: col-resize;
  user-select: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ccc;
}

.resizer:hover {
  background-color: gray;
}

@media print {
  .pdf-hide {
    display: none !important;
  }
}

@media print {
  tr {
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* Ensures headers repeat on each page */
  }

  table {
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid black; /* Optional for clarity in PDFs */
  }
}

tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  word-wrap: break-word;
}

th {
  text-align: center;
}

td {
  text-align: left;
}

td,
th {
  white-space: nowrap;
  white-space: normal;
}

.table-box {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
}

table thead th {
  top: 0;
}

/* select{
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
} */

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.intervention-counts {
  text-align: center;
}

.counts-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.count-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 150px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.count-card h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  min-height: 40px; /* Ensures consistent height for titles */
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-card p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
