.leave-form-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.success-message {
  color: green;
  text-align: center;
  font-size: 16px;
}
.rm{
  font-size: 18px;
  font-weight: 500;
}
.error-message {
  color: red;
  text-align: center;
  font-size: 16px;
}
.leave-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.leave-form-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mailto,
.mailto2 {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mailto3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailto label,
.mailto2 label {
  font-weight: bold;
  text-align: left;
  color: #000000;
  margin: 0px;
}

.mailto input,
.mailto2 input,
.mailto2 select {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.file-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-upload input[type="file"] {
  flex: 1;
}

button {
  padding: 10px 20px;
  background-color: #1877f2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
