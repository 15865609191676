/* updateBmcIntervention.css */
.update-bmc-intervention {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f7fa;
  padding: 20px; /* Add padding for smaller screens */
}

.update-bmc-intervention form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px; /* Increased width for better layout */
}

.update-bmc-intervention h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Create a row layout for 3 fields */
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Spacing between fields */
  margin-bottom: 15px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.form-group {
  flex: 1 1 ; /* Default to 100% width */
  display: flex;
  flex-direction: column;
  min-width: 256px; /* Minimum width for input fields */
}

label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

input,
select {
  width: 256px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Ensure textarea behaves like a single-line input */
textarea {
  resize: none;
  min-height: unset;
  height: 100px;
}

/* Focus state for inputs */
input:focus,
textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

button.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.submit-button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column; /* Stack fields vertically */
    gap: 10px;
  }

  .form-group {
    min-width: 100%; /* Full width for inputs on smaller screens */
  }

  .update-bmc-intervention form {
    padding: 20px; /* Reduce padding */
  }

  .update-bmc-intervention h2 {
    font-size: 20px; /* Smaller font size */
  }
}

@media (max-width: 480px) {
  input,
  select,
  textarea {
    font-size: 12px; /* Reduce font size for inputs */
    padding: 8px; /* Reduce padding */
  }

  button.submit-button {
    font-size: 14px; /* Reduce font size */
    padding: 10px; /* Adjust padding */
  }

  .update-bmc-intervention form {
    padding: 15px; /* Smaller padding for very small screens */
  }

  .update-bmc-intervention h2 {
    font-size: 18px; /* Further reduce heading size */
  }
}
