.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%; /* Full width of the page */
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  box-sizing: border-box;
}

.leave-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px; /* Fix column widths to prevent overflow */
}

.date-filter2{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leave-table th,
.leave-table td {
  border: 1px solid #ddd;
  padding: 6px;
  word-wrap: break-word; /* Wrap text to prevent overflow */
  white-space: normal; /* Allow text wrapping */
}

.leave-table th {
  background-color: #1877f2;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

.leave-table td {
  text-align: center;
  font-size: 14px;
}

.leave-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leave-table tr:hover {
  background-color: #f1f1f1;
}

.search-bar input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 280px;
}

select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.status-approved {
  background-color: #b6d7a8;
}

.status-not-approved {
  background-color: #ea9999;
}

.status-pending {
  background-color: #ffe599;
}
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li.active {
  font-weight: bold;
  color: #007bff;
}

.pagination li a {
  text-decoration: none;
  color: inherit;
}

.toggle-text {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
