.form-container {
  width: 900px;
  max-height: fit-content;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #dddfe2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: system-ui, "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
}
.ck-editor__editable_fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: white;
}

.fullscreen-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 9999;
  padding: 20px;
}

.fullscreen-editor .ql-toolbar,
.fullscreen-editor .ql-container {
  height: calc(100% - 42px); /* Adjust the height based on toolbar height */
}

.fullscreen-editor .ql-container {
  height: 100%;
}

label {
  display: flex;
  font-weight: bold;
  flex-direction: column;
}

.quill-editor {
  height: 300px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}
.form-row2 {
  display: flex;
  justify-content: space-between;
}
small {
  text-align: end;
}
.radio-div {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: space-evenly;
  align-items: center;
}
.radio-button {
  width: fit-content;
}
.radio-add-button {
  width: fit-content;
}
input {
  width: 280px;
  font-size: 14px;
  padding: 10px;
  margin: 10px 0px;
  box-sizing: border-box;
  border: 1px solid #dddfe2;
  border-radius: 4px;
  display: inline-block;
}
input:hover,
select:hover,
textarea:hover {
  box-shadow: 0 0 10px rgba(171, 171, 223, 0.5);
}
.button-submit {
  display: flex;
  justify-content: center;
}
button {
  width: 300px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
  box-sizing: border-box;
  border: 1px solid #dddfe2;
  border-radius: 4px;
  display: inline-block;
}
.upload {
  display: flex;
  flex-direction: column;
}

textarea {
  width: 100%;
  font-size: 14px;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #dddfe2;
  border-radius: 4px;
  display: inline-block;
}

select {
  width: 280px;
  font-size: 14px;
  padding: 10px;
  margin: 10px 0px;
  box-sizing: border-box;
  border: 1px solid #dddfe2;
  border-radius: 4px;
  display: inline-block;
}

button {
  width: 300px;
  background-color: #1877f2;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

button:hover {
  background-color: #1467c0;
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}

.form-container > * {
  margin-bottom: 16px;
}

/* body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
} */
